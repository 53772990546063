import * as React from "react";
import { graphql } from "gatsby";
import { StaticQuery } from "gatsby";
import classnames from "classnames";
import {Link} from "@fitplan/lib/components/Link";

import { localeTextGetter } from "../../utils/l10n";
import { Container, Columns, Column } from "../primitives";
import Icon from "@fitplan/lib/components/Icons";
import VideoModal from "../video/VideoModal";

import "./about.scss";

const isOdd = (n: number): boolean => !!(n % 2 === 1);

const isEven = (n: number): boolean => !isOdd(n);

const TEXT_COLLAPSE_OPTIONS = {
    collapse: false,
    collapseText: "Read More",
    expandText: "",
    minHeight: 70,
    maxHeight: 470,
};

const About: React.SFC<{}> = props => {
    const [isOpen, setIsOpen] = React.useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <StaticQuery
            query={graphql`
                query AboutQuery {
                    sanityAbout {
                        aboutTitle {
                            _type
                            en
                            es
                        }
                        aboutText {
                            _type
                            en
                            es
                        }
                        aboutVideo
                        aboutButton {
                            _type
                            en
                            es
                        }
                        founderTitle {
                            _type
                            en
                            es
                        }
                        founders {
                            name
                            position {
                                _type
                                en
                                es
                            }
                            description {
                                _type
                                en
                                es
                            }
                            instagram
                            twitter
                            linkedin
                            image {
                                asset {
                                    url
                                }
                            }
                        }
                    }
                }
            `}
            render={data => {
                const {
                    sanityAbout: {
                        aboutTitle,
                        aboutText,
                        aboutVideo,
                        aboutButton,
                        founders,
                        founderTitle,
                    },
                } = localeTextGetter(data);

                return (
                    <section className="about">
                        <section className="about__introducing-section">
                            <Container>
                                <Columns>
                                    <Column className="about__introducing-column" />
                                    <Column>
                                        <button
                                            className="about__introducing-button"
                                            onClick={() => openModal()}
                                        >
                                            <Icon type="right" />{" "}
                                            {aboutButton}
                                        </button>
                                        <h1 className="about__introducing-title">
                                            {aboutTitle}
                                        </h1>
                                        <p className="about__introducing-text">
                                            {aboutText}
                                        </p>
                                    </Column>
                                    <Column className="about__introducing-column" />
                                </Columns>
                            </Container>
                        </section>
                        <section className="about__founders-section">
                            {founders.map((founder: any, index: number) => (
                                <Container
                                    key={founder.name}
                                    className={classnames(
                                        "about__founders-container",
                                        {
                                            left: isOdd(index),
                                        }
                                    )}
                                >
                                    <Column
                                        className={classnames(
                                            "about__founders-img",
                                            {
                                                left: isOdd(index),
                                            }
                                        )}
                                        style={{
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            backgroundImage:
                                                "url(" +
                                                 (index === 0 ? "https://images.fitplanapp.com/web/about/landon-photo.jpg?tr=w-800" : "https://images.fitplanapp.com/web/about/cam-photo.jpg?tr=w-800") +
                                                ")",
                                        }}
                                    />
                                    <Column
                                        className={classnames(
                                            "about__founders-details",
                                            {
                                                left: isOdd(index),
                                            }
                                        )}
                                    >
                                        {isEven(index) && (
                                            <div className="about__founders-top">
                                                {founderTitle}
                                                <hr />
                                            </div>
                                        )}
                                        <div className="about__founders-bottom">
                                            <div className="about__founders-name">
                                                {founder.name}
                                            </div>
                                            <div className="about__founders-position">
                                                {founder.position}
                                            </div>
                                            <div className="about__founders-description">
                                                {founder.description}
                                                <br />
                                                <div className="about__founders-link">
                                                    <Link
                                                        to={founder.twitter}
                                                    >
                                                        <Icon
                                                            type="faTwitter"
                                                            className="about__founders-icon"
                                                        />
                                                    </Link>
                                                    <Link
                                                        to={
                                                            founder.instagram
                                                        }
                                                    >
                                                        <Icon
                                                            type="faInstagram"
                                                            className="about__founders-icon"
                                                        />
                                                    </Link>
                                                    <Link
                                                        to={
                                                            founder.linkedin
                                                        }
                                                    >
                                                        <Icon
                                                            type="faLinkedin"
                                                            className="about__founders-icon"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Column>
                                    {isOdd(index) && (
                                        <Column
                                            className="about__founders-img"
                                            style={{
                                                backgroundSize: "cover",
                                                backgroundRepeat:
                                                    "no-repeat",
                                                backgroundImage:
                                                    "url(" +
                                                    (index === 0 ? "https://images.fitplanapp.com/web/about/landon-photo.jpg?tr=w-800" : "https://images.fitplanapp.com/web/about/cam-photo.jpg?tr=w-800") +
                                                    ")",
                                            }}
                                        />
                                    )}
                                </Container>
                            ))}
                        </section>
                        <VideoModal
                            show={isOpen}
                            onClose={closeModal}
                            url={aboutVideo}
                            vimeo
                        />
                    </section>
                );
            }}
        />
    );
};

export default About;
