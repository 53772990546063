import * as React from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Footer from "../footer/footer";
import { StickyContainer, Sticky } from "react-sticky";
import { StaticHeaderWrapper } from "../header/StaticHeader";

interface Props {
    hideBanner?: boolean;
}

export const Layout: React.SFC<Props> = ({ children, hideBanner, ...props }) => {
    const data = useStaticQuery(graphql`
        query LayoutQuery {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                }
            }
            sanityAsset {
                logo {
                    asset {
                        url
                    }
                }
                logoSmall {
                    asset {
                        url
                    }
                }
            }
            metadata {
                title
                description
            }
        }
    `);
    return (
        <StickyContainer>
            <Helmet
                title={data.metadata.title}
                meta={[
                    {
                        name: "description",
                        content: data.metadata.description,
                    },
                    {
                        name: "keywords",
                        content: data.site.siteMetadata.keywords.join(", "),
                    },
                ]}
                link={[
                    {
                        rel: "shortcut, icon",
                        type: "image/png",
                        href: "/images/favicon/favicon.ico",
                    },
                    {
                        rel: "apple-touch-icon",
                        href: "/images/favicon/apple-touch-icon.png",
                    },
                    {
                        rel: "apple-touch-icon",
                        sizes: "76x76",
                        href: "/images/favicon/apple-touch-icon-76x76.png",
                    },
                    {
                        rel: "apple-touch-icon",
                        sizes: "120x120",
                        href: "/images/favicon/apple-touch-icon-120x120.png",
                    },
                    {
                        rel: "apple-touch-icon",
                        sizes: "152x152",
                        href: "/images/favicon/apple-touch-icon-152x152.png",
                    },
                    {
                        rel: "apple-touch-icon",
                        sizes: "180x180",
                        href: "/images/favicon/apple-touch-icon-180x180.png",
                    },
                ]}
            />
            <Sticky>
                {({ style }) => <StaticHeaderWrapper hideBanner={hideBanner} style={style} />}
            </Sticky>
            {children}
            <Footer logo={data.sanityAsset.logoSmall.asset.url} />
        </StickyContainer>
    );
};

export default Layout;
