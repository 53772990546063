import * as React from 'react';

import localize from '../hoc/Localize';
import About from "../components/about/about";
import Layout from "../components/layout";

const AboutPage = props => (
  <Layout {...props}>
    <About />
  </Layout>
);

export default localize(AboutPage);
