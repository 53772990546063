import * as React from "react";
import Icon from "../../icons";
import "./video-modal.scss";
import styled from "@emotion/styled";

const backdropStyle: any = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.86)",
    padding: 50,
};

export interface Props {
    onClose: () => void;
    show: boolean;
    url: string;
    vimeo?: boolean;
}

const VideoModal: React.SFC<Props> = props => {
    if (!props.show) {
        return null;
    }
    return (
        <div className="video__backdrop" style={backdropStyle}>
            <div className="video__modal">
                {!props.vimeo ? (
                    <video
                        className="video___video"
                        controls
                        autoPlay
                        preload="auto"
                        width="auto"
                        height="350px"
                    >
                        <source src={props.url} />
                    </video>
                ) : (
                    <IFrame
                        src={props.url}
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                    />
                )}
            </div>
            <button
                className="video__close-button"
                onClick={() => props.onClose()}
            >
                <Icon type="faTimes" />
            </button>
        </div>
    );
};

export default VideoModal;

const IFrame = styled.iframe<any>`
    width: 600px;
    height: 350px;
`;